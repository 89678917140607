import styled from '@emotion/styled';
import React from 'react'

import ImageLoader from '../assets/brand/newLoadingAnimation.gif'

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: 99vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentLoader = () => {
  return (
    <Wrapper>
      <img src={ImageLoader} alt="Baleomol Animation" />
    </Wrapper>
  )
}

export default ContentLoader