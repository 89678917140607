import styled from '@emotion/styled'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { ExpandMore, InfoOutlined } from '@material-ui/icons'
import React from 'react'
import Landscape from '../../../../assets/ktp/Landscape.png'
import Portrait from '../../../../assets/ktp/Portrait.png'
import ResponsiveImage from '../../../../components/ResponsiveImage'

const SAccordion = styled(Accordion)`
  margin: 0 !important;
  padding: 0px !important;
  border-radius: 12px !important;
  border: 1px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  background: rgba(248, 250, 252, 1) !important;
  border: 1px solid rgba(205, 213, 223, 1) !important;
  box-shadow: none !important;
  // overflow: hidden !important;
  &:before {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    content: "";
    opacity: 0 !important;
    position: absolute !important;
    transition: none !important;
    background-color: transparent !important;
}
`
const SAccordionSummary = styled(AccordionSummary)`
  margin: 0 !important;
  overflow: hidden !important;
`
const Title = styled(Typography)`
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(0, 104, 225, 1) !important;
  display: flex;
  align-items: center;
  gap: .5rem;
`

const Subtitle = styled(Typography)`
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(75, 85, 101, 1) !important;
  display: flex;
  align-items: center;
  gap: .5rem;
`
const SAccordionDetails = styled(AccordionDetails)`
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  background: rgba(248, 250, 252, 1) !important;
  box-shadow: none !important;
  gap: .5rem;
  overflow: hidden !important;
`

const ExampleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: .5rem;
  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;  
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: .8rem;
  & > div {
    width: inherit;
  }
  & img {
    width: 100%;
    border-radius: 8px;
  }
`

const IdentityUploadGuide = () => {
  return (
    <SAccordion>
      <SAccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Title> <InfoOutlined /> Panduan Upload Foto KTP</Title>
      </SAccordionSummary>
      <SAccordionDetails>
        <Subtitle>Anda dapat mengunggah foto KTP dengan posisi portrait atau landscape seperti contoh di bawah ini:</Subtitle>
        <ExampleWrapper>
          <div className="">
            <Subtitle>Portrait</Subtitle>
            <ImageWrapper>
              <ResponsiveImage
                type="profileDetail"
                ratio="1:1"
                src={Portrait}
                alt="Identity"
              />
            </ImageWrapper>
          </div>
          <div className="">
            <Subtitle>Landscape</Subtitle>
            <ImageWrapper>
              <ResponsiveImage
                type="profileDetail"
                ratio="1:1"
                src={Landscape}
                alt="Identity"
              />
            </ImageWrapper>
          </div>
        </ExampleWrapper>
      </SAccordionDetails>
    </SAccordion>
  )
}

export default IdentityUploadGuide