/* eslint-disable no-unused-vars */
import { Backdrop } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import LoadingBaleomol from "../../../../assets/brand/loading.gif";
import DefaultImage from "../../../../assets/ktp/default-image.png";
import ResponsiveImage from '../../../../components/ResponsiveImage';
import { useAccountVerify } from '../../../../hooks/useAccountVerify';
import useAxiosFunction from '../../../../hooks/useAxiosFunction';
import ActionType from '../../../../redux/reducer/globalActionType';
import { FooterContent, Identity, ImageWrapper, VerifyButton } from './style';

const FormVerifyAccount = ({ token, setAccount, reduxAccount, ...props }) => {
  const { account } = useSelector(state => state)
  const isIdentityExists = account?.data?.ktp
  const { image, status, statusInfo } = account?.data?.ktp || {}
  const isAccountVerified = status === 'accept'
  const { isModalOpen, setIsModalOpen } = useAccountVerify()
  const { dispatchData } = useAxiosFunction();
  const [isUploading, setIsUploading] = useState(false)
  const [displayImage, setDisplayImage] = useState(image || DefaultImage)
  const [form, setForm] = useState({
    picture: ''
  })
  const [isFilled, setIsFilled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const alertRef = useRef()

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      setForm(prev => ({ ...prev, picture: event.target.files[0] }))
      return
    }
    setDisplayImage('')
    setForm(prev => ({ ...prev, picture: '' }))
    return
  };

  const uploadPicture = async () => {
    try {
      const formData = new FormData();
      formData.append("file", form.picture);
      setIsUploading(true);
      setIsError(false);
      setIsSuccess(false);
      const response = await dispatchData({
        url: `/ktp`,
        method: 'post',
        credentials: token,
        data: formData
      }).catch((err) => {
        return err.response
      });
      const message = response?.data?.message;
      const status = response?.status;
      const success = response?.data;
      if (response?.status > 300) {
        throw new Error(message || 'Error occured');
      }
      const data = response?.data?.data || [];
      setAccount({
        data: {
          ...reduxAccount.data,
          ktp: data
        },
        updated: Date.now(),
      });
      setMessage(message)
      setIsModalOpen(false)
    } catch (err) {
      console.log({ err });
      setIsError(true)
      setMessage(err.message || 'Error ketika proses upload!')
    } finally {
      setIsUploading(false);
    }
  }

  useEffect(() => {
    if (form.picture) {
      setIsFilled(form.picture !== '')
    }
  }, [form])

  useEffect(() => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [alertRef])

  return (
    <Identity>
      {isUploading && (
        <Backdrop open={true} style={{ zIndex: "99" }}>
          <img src={LoadingBaleomol} alt="loading" />
        </Backdrop>
      )}
      {!isUploading && isError && <Alert ref={alertRef} severity='error'>{message}</Alert>}
      {!isUploading && isSuccess && <Alert ref={alertRef} severity='success'>{message}</Alert>}
      {isIdentityExists && (
        <form>
          <ImageWrapper>
            <ResponsiveImage
              type="profileDetail"
              ratio="1:1"
              src={displayImage}
              alt="Identity"
              onError={() => setDisplayImage(DefaultImage)}
            />
          </ImageWrapper>
          {(status === '' || status === 'reject') && (
            <FooterContent>
              <input
                accept="image/*"
                id="identity-chose-button"
                name="profile"
                type="file"
                onChange={(e) => changeHandler(e)}
                hidden
              />
              <label htmlFor="identity-chose-button">
                <VerifyButton
                  component="span"
                >
                  Pilih Foto KTP
                </VerifyButton>
              </label>
              <VerifyButton
                primary
                component="span"
                disabled={!isFilled}
                onClick={uploadPicture}
              >
                Upload Foto
              </VerifyButton>
            </FooterContent>
          )}
        </form>
      )}
      {!isIdentityExists && (
        <Alert severity='info'>Sepertinya session anda usang, silahkan keluar <em>(logout)</em> dan masuk <em>(login)</em> kembali.</Alert>
      )}
    </Identity>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    setAccount: (account) =>
      dispatch({
        type: ActionType.SET_ACCOUNT,
        payload: { ...account }
      }),
  }
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
    idMember: state.idMember,
    token: state.token,
    screen: state.tpdvc,
    reduxAccount: state.account,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormVerifyAccount);
