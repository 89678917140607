import axios from "axios";
import { Backend } from "../services/Config";

const API_URL = `${Backend.replace(/\/$/, "")}`

// Fungsi untuk menangani respons API
export const handleResponse = (response) => {
  if (response?.data) {
    return response.data;
  }
  console.warn('API response error:', response?.data?.message || 'Unknown error');
  return undefined;
};
export const handleResponseError = (err) => {
  return undefined
};

export const callAPI = async (requestConfig, token) => {
  const ctrl = new AbortController(); // Membuat instance AbortController
  const { url, ...options } = requestConfig;

  // Mengatur URL API
  const fullUrl = `${API_URL}${url}`;

  // Menyiapkan header jika token ada
  const headers = token
    ? {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      ...options.headers, // Memastikan header tambahan tidak terhapus
    }
    : options.headers;

  try {
    // Melakukan request dengan axios
    const response = await axios.request({
      ...options,
      url: fullUrl,
      headers,
      signal: ctrl.signal, // Menghubungkan AbortController dengan request
    });

    return response.data; // Mengembalikan data dari response

  } catch (error) {
    if (axios.isCancel(error)) {
      console.error('Request canceled', error.message);
    } else {
      console.error('API call error', error.message);
    }
    // throw error?.response?.data || error; // Menyebarkan error untuk ditangani di level yang lebih tinggi
    throw error
  } finally {
    // Membersihkan AbortController jika diperlukan
    ctrl.abort();
  }
};
