import { ASSET_REGEX, BREAKPOINTS, IMAGE_TYPES } from "./useResponsiveAssets.config";

const useResponsiveAssets = ({
  src = '',
  breakpoints = {},
  type = '',
  ratio = '',
  format = '',
}) => {
  const getWidthBySize = (type, size) => {
    if (type[size]) return type[size];

    const index = Object.keys(BREAKPOINTS).findIndex((item) => item === size);
    const closest = Object.keys({ ...BREAKPOINTS })
      .filter((item, i) => i < index)
      .filter(
        (item) => Object.keys(type).includes(item),
      ).pop();

    return type[closest];
  };

  const isSmScreen = window.innerWidth >= BREAKPOINTS.sm;
  const isMdScreen = window.innerWidth >= BREAKPOINTS.md;
  const isLgScreen = window.innerWidth >= BREAKPOINTS.lg;
  const isXlScreen = window.innerWidth >= BREAKPOINTS.xl;
  let size = 'xs';

  const imageType = IMAGE_TYPES[type];
  const isImageBaleomol = src?.toLowerCase()?.match(ASSET_REGEX);

  if (!isImageBaleomol || (!imageType && JSON.stringify(breakpoints) === '{}')) return src;

  if (!imageType && JSON.stringify(breakpoints) !== '{}') {
    const width = getWidthBySize(breakpoints, size);
    let height = 0;

    if (ratio && ratio.match(/(?:\d)(:)(?=\d$)/gi)) {
      const ratioNumbers = ratio.split(':');
      const heightRatio = Number(ratioNumbers[1]) / Number(ratioNumbers[0]);
      height = Math.ceil(width * heightRatio);
    }

    return height ? `${src}?w=${width}&h=${height}` : `${src}?w=${width}`;
  }

  if (isSmScreen) size = 'sm';
  if (isMdScreen) size = 'md';
  if (isLgScreen) size = 'lg';
  if (isXlScreen) size = 'xl';

  const width = getWidthBySize(imageType, size);
  let height = 0;

  if (ratio && ratio.match(/(?:\d)(:)(?=\d$)/gi)) {
    const ratioNumbers = ratio.split(':');
    const heightRatio = Number(ratioNumbers[1]) / Number(ratioNumbers[0]);
    height = Math.ceil(width * heightRatio);
  }

  let result = `${src}?w=${width}`;

  if (height) result += `&h=${height}`;
  if (format) result += `&format=${format}`;

  return encodeURI(result);
};

export default useResponsiveAssets;
