/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useAccountVerify } from '../../../../hooks/useAccountVerify';
import FormVerifyAccount from '../../organisms/VerifyAccount/FormVerifyAccount';
import { AccountVerifyBadgeStatus, ModalHelper } from '../../organisms/VerifyAccount/style';
import Modal, { ModalBody } from './index';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import IdentityUploadGuide from '../../organisms/VerifyAccount/IdentityUploadGuide';

const ModalVerifyAccount = ({ ...props }) => {
  const history = useHistory()
  const { pathname, state } = useLocation()
  const { isModalOpen, setIsModalOpen } = useAccountVerify()

  const handleClose = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    setIsModalOpen(false)
  }, [pathname])

  useEffect(() => {
    if (state?.openModalVerifyAccount) {
      setIsModalOpen(true)
      if (isModalOpen) {
        history.replace({
          pathname,
          state: undefined
        })
      }
    }
  }, [state])

  useEffect(() => {
    const bodyEl = document.querySelector('body');
    const isNoScroll = bodyEl.classList.contains('body--no-scroll');
    if (isModalOpen) {
      if (!isNoScroll) {
        bodyEl.classList.add('body--no-scroll');
      }
      return () => {
        bodyEl.classList.remove('body--no-scroll');
      }
    }
    return () => {
      bodyEl.classList.remove('body--no-scroll');
    }
  }, [isModalOpen])


  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      header={{
        text: 'Verifikasi Akun'
      }}
    >
      <ModalBody>
        <ModalHelper>
          <span className='title'><b>INFORMASI PENTING</b></span>
          <span>Verifikasi akun Anda dengan mengunggah foto KTP. Langkah ini membantu memastikan bahwa Anda adalah pemilik sah dari akun ini. Kami menjamin kerahasiaan informasi pribadi Anda untuk memberikan layanan terbaik.</span>
        </ModalHelper>
        <IdentityUploadGuide />
        <AccountVerifyBadgeStatus />
        <FormVerifyAccount />
      </ModalBody>
    </Modal >
  )
}

export default ModalVerifyAccount