/* eslint-disable no-unused-vars */
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import useSocket from "../../../hooks/useSocket";
import useChatConversations from "./use-chat-conversations";
import useChatLists from "./use-chat-list";
import useChatStore from "./use-chat-store";

const playAudio = () => {
  const audio = new Audio(
    'https://is3.cloudhost.id/baleomoldev/assets/notification_sound.mp3'
  )
  audio.addEventListener('canplaythrough', (event) => {
    audio.play()
  })
}

const ChatContext = createContext();

export const ChatComponent = ({ children, ...props }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1223px)' })
  const { username, isLogin } = useSelector(state => state)
  const { socket, token } = useSocket()
  const {
    isStoreChatOpen,
    setIsStoreChatOpen,
    openRoomStore,
    resetOpenRoomStore,
    setOpenRoomStore,
    resetOpenChatStore,
  } = useChatStore()
  const [openMobile, setOpenMobile] = useState(false)
  const canPlayAudio = useRef(true);
  const audioTimeout = useRef(null);

  /**
   * chat conversation list
   */

  const {
    isLoadingList,
    isErrorList,
    messageList,
    conversationList,
    setConversationList,
    conversationLists,
    fetchLists,
    unreadMessages,
    setUnreadMessages,
    totalUnread,
    setTotalUnread,
    typeUser,
    setTypeUser,
    search,
    setSearch,
    resetSearch,
    pageList,
    setPageList,
    hasMoreList,
    handleSearch,
    handleTypeUser,
    notifications,
    setNotifications,
    fetchNotifications,
    addMessageToList,
  } = useChatLists()

  const [previewData, setPreviewData] = useState({
    type: null,
    data: null
  })

  const resetPreviewData = () => {
    setPreviewData({
      type: null,
      data: null,
    })
  }

  const resetLists = () => {
    setPageList(1)
    setConversationList([])
  }

  const toggleOpenChat = () => {
    resetLists()
    setIsStoreChatOpen(!isStoreChatOpen)
    resetOpenRoomStore()
    resetSearch()
    if (isMobile) setOpenMobile(!openMobile)
  }

  const setOpenRoom = (item) => {
    setOpenRoomStore(item)
  }

  const resetOpenRoom = () => {
    resetOpenRoomStore()
    if (previewData?.type) {
      resetPreviewData()
    }
  }

  const resetOpenChat = () => {
    resetOpenChatStore()
    resetSearch()
    resetOpenRoomStore()
    if (isMobile && openMobile) setOpenMobile(!openMobile)
  }

  const handleChatSeller = (data) => {
    const sellerUsername = data?.username
    setTypeUser("seller")
    setIsStoreChatOpen(true)
    setSearch(sellerUsername)
    if (isMobile) setOpenMobile(!openMobile)
  }

  const handleChatMitra = (data) => {
    const mitraUsername = data?.username
    setTypeUser("mitra")
    setIsStoreChatOpen(true)
    setSearch(mitraUsername)
    if (isMobile) setOpenMobile(!openMobile)
  }

  const handlePreviewProduct = ({ product, data }) => {
    const sellerUsername = data?.username
    setIsStoreChatOpen(true)
    setPreviewData({
      type: 'product',
      data: product
    })
    setTypeUser("seller")
    setSearch(sellerUsername)
    if (isMobile) setOpenMobile(!openMobile)
  }

  const handlePreviewOrder = ({ order, data }) => {
    const sellerUsername = data?.username
    setIsStoreChatOpen(true)
    setPreviewData({
      type: 'order',
      data: order
    })
    setTypeUser("seller")
    setSearch(sellerUsername)
    if (isMobile) setOpenMobile(!openMobile)
  }

  /**
   * chat conversation
   */
  const {
    messages,
    formattedMessages,
    setMessages,
    isMessageExists,
    hasMoreMessages,
    setHasMoreMessages,
    lastKey,
    setLastKey,
    scrollTo,
    setScrollTo,
    isLoadingMessages,
    setIsLoadingMessages,
    isErrorMessages,
    setIsErrorMessages,
    isLoadingSendAttachment,
    setIsLoadingSendAttachment,
    isLoadingSendMessage,
    setIsLoadingSendMessage,
    isLoadingSendMessagePreview,
    setIsLoadingSendMessagePreview,
    messageMessages,
    setMessageMessages,
    messagePreview,
    setMessagePreview,
    messageSendAttachment,
    setMessageSendAttachment,
    messageSendMessage,
    setMessageSendMessage,
    formattedMessage,
    fetchMessages,
    sendMessage,
    sendAttachment,
    addMessageToConversation,
  } = useChatConversations()


  const updateNotifications = () => {
    if (!isLoadingList && conversationList?.length > 0) {
      if (typeUser === 'seller') {
        const seller = conversationList?.some((item) => item?.unread > 0)
        setNotifications(prev => ({
          ...prev,
          seller: {
            ...prev?.seller,
            active: seller
          }
        }))
        return
      }
      if (typeUser === 'mitra') {
        const mitra = conversationList?.some((item) => item?.unread > 0)
        setNotifications(prev => ({
          ...prev,
          mitra: {
            ...prev?.mitra,
            active: mitra
          }
        }))
        return
      }
    }
  }

  const [errorBoundaryConversations, setErrorBoundaryConversations] = useState([])

  const handleErrorBoundaryConversations = useCallback(
    (id = false) => {
      if (id) {
        if (id === 'error-message-send-attachment') {
          setMessageSendAttachment(null)
        }
        if (id === 'error-message-send-preview') {
          setMessagePreview(null)
        }
        if (id === 'error-message-send-message') {
          setMessageSendMessage(null)
        }
        if (id === 'error-message-messages') {
          setMessageMessages(null)
        }
        setErrorBoundaryConversations(prev => {
          return prev?.filter(item => item?.id !== id)
        })
        return
      }
      setErrorBoundaryConversations([])
      return
    }, [setMessageMessages, setMessagePreview, setMessageSendAttachment, setMessageSendMessage])

  const updateErrorBoundary = useCallback(
    (id, message) => {
      setErrorBoundaryConversations(prev => {
        return [
          ...prev,
          {
            id,
            message
          }
        ]
      })
    }, [])

  useEffect(() => {
    let timeoutId = null
    if (messageSendAttachment) {
      timeoutId = setTimeout(() => {
        updateErrorBoundary('error-message-send-attachment', messageSendAttachment)
      }, 300);
    }
    return () => clearTimeout(timeoutId)
  }, [messageSendAttachment, updateErrorBoundary])

  useEffect(() => {
    let timeoutId = null
    if (messagePreview) {
      timeoutId = setTimeout(() => {
        updateErrorBoundary('error-message-send-preview', messagePreview)
      }, 300);
    }
    return () => clearTimeout(timeoutId)
  }, [messagePreview, updateErrorBoundary])

  useEffect(() => {
    let timeoutId = null
    if (messageSendMessage) {
      timeoutId = setTimeout(() => {
        updateErrorBoundary('error-message-send-message', messageSendMessage)
      }, 300);
    }
    return () => clearTimeout(timeoutId)
  }, [messageSendMessage, updateErrorBoundary])

  useEffect(() => {
    let timeoutId = null
    if (messageMessages) {
      timeoutId = setTimeout(() => {
        updateErrorBoundary('error-message-messages', messageMessages)
      }, 300);
    }
    return () => clearTimeout(timeoutId)
  }, [messageMessages, updateErrorBoundary])

  useEffect(() => {
    /**
     * if not use isLogin
     * after login this hooks can not trigger
     */
    let timeoutId = null
    if (isStoreChatOpen) {
      timeoutId = setTimeout(() => {
        if (isLogin && token) {
          const obj = {}
          obj.search = search
          if (typeUser) {
            obj.typeUser = typeUser
          }
          if (pageList) {
            obj.pageList = pageList
          }
          if (previewData?.type) {
            obj.previewData = previewData
          }
          fetchLists(obj)
        }
      }, 300);
    } else {
      timeoutId = setTimeout(() => {
        if (isLogin && token) {
          const obj = {}
          obj.search = search
          if (typeUser) {
            obj.typeUser = typeUser
          }
          if (pageList) {
            obj.pageList = 1
          }
          fetchLists(obj)
        }
      }, 300);
    }
    return () => {
      timeoutId && clearTimeout(timeoutId)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, typeUser, token, isLogin, isStoreChatOpen]);

  useEffect(() => {
    /**
     * Handle display open room based on searh and set open room by username
     */
    if (!isLoadingList && search && conversationList) {
      const isSearchOnList = conversationList?.length === 1 && conversationList[0].members.includes(search);
      if (isSearchOnList) {
        setOpenRoomStore(conversationList[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, conversationList, isLoadingList])

  useEffect(() => {
    /**
     * Handle on message
     * play audio
     * add message to list
     * fetch notification
     */
    if (!socket) return
    if (!isLogin) return

    socket?.on('messageV2', (msg) => {
      if (canPlayAudio.current) {
        /**
         * Handling play sound and fecth notification
         * 
         */
        console.log('Play Sound')
        playAudio();
        fetchNotifications()
        canPlayAudio.current = false;
        // Set a timeout to re-enable sound after 3 seconds
        audioTimeout.current = setTimeout(() => {
          canPlayAudio.current = true;
        }, 3000);
      }
      addMessageToList(msg)
    })
    return () => {
      if (audioTimeout.current) {
        clearTimeout(audioTimeout.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, isLogin, audioTimeout])

  useEffect(() => {
    /**
     * Handle adding message to conversation and list
     * when has new message
     */
    const id = openRoomStore?.id
    if (!socket || !id) return
    if (messages) {
      socket.on('messageV2', (msg) => {
        addMessageToConversation(id, msg, messages)
        addMessageToList(msg)
      })
    }
    return () => {
      socket.off('messageV2')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, openRoomStore, messages])

  useEffect(() => {
    /**
     * Update notification BE only 10 items
     */
    if (isLogin) { fetchNotifications() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeUser, isLogin])

  useEffect(() => {
    /**
     * Handle Total Unread Messages
     */
    if (!isLoadingList && conversationList?.length > 0) {
      const countUnread = conversationList?.reduce((acc, item) => {
        if (item.unread_message) {
          return acc + 1
        }
        return acc
      }, 0);
      setTotalUnread(countUnread > 9 ? '9+' : countUnread);

      /**
       * Handle Unread Messages
       */

      const newUnreadMessages = conversationList?.reduce((acc, item) => {
        if (item.unread_message > 0) {
          acc[item?.conversation_id] = {
            role: item.role,
            unread: item.unread_message,
          }
        }
        return acc
      }, {})
      setUnreadMessages(newUnreadMessages)
    }
  }, [isLoadingList, conversationList, setTotalUnread, setUnreadMessages]);

  useEffect(() => {
    /**
    * Handle chat conversation messages
    */
    const timeoutId = setTimeout(() => {
      const id = openRoomStore?.id
      fetchMessages(id)
    }, 100);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRoomStore?.id])

  useEffect(() => {
    /**
     * fetchNotifications from BE only set limit 10
     * update notification if user has scrolled list
     */
    !isLoadingList && updateNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingList, conversationList])

  const value = {
    openChat: isStoreChatOpen,
    resetOpenChat,
    openRoom: openRoomStore,
    isOpenRoom: openRoomStore?.id,
    setOpenRoom,
    resetOpenRoom,
    openMobile,
    setOpenMobile,
    previewData,
    setPreviewData,
    resetPreviewData,
    handlePreviewProduct,
    handlePreviewOrder,
    isLoadingList,
    isErrorList,
    messageList,
    conversationList,
    conversationLists,
    fetchLists,
    toggleOpenChat,
    unreadMessages,
    totalUnread,
    typeUser,
    search,
    resetSearch,
    pageList,
    hasMoreList,
    handleChatSeller,
    handleChatMitra,
    handleSearch,
    handleTypeUser,
    notifications,
    fetchNotifications,
    messages,
    formattedMessages,
    hasMoreMessages,
    lastKey,
    scrollTo,
    isMessageExists,
    isLoadingMessages,
    isErrorMessages,
    fetchMessages,
    addMessageToConversation,
    isLoadingSendAttachment,
    messageSendAttachment,
    sendAttachment,
    isLoadingSendMessage,
    isLoadingSendMessagePreview,
    sendMessage,
    errorBoundaryConversations,
    handleErrorBoundaryConversations,
  }

  return (
    <ChatContext.Provider
      value={value}
    >
      {children}
    </ChatContext.Provider>
  );
};


export const ChatProvider = ChatComponent;

const useChat = () => useContext(ChatContext);

export default useChat;