/* eslint-disable no-unused-vars */
const A_DAY = 60 * 60 * 24

const TWO_DAYS = 2 * A_DAY

const A_WEEK = 7 * A_DAY

const WEEKDAYS_FORMATTER = Intl.DateTimeFormat('id-ID', {
  weekday: 'long',
  hour: 'numeric',
  minute: 'numeric',
})

const LOCALE_TIME_FORMATTER = Intl.DateTimeFormat('id-ID', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
})

const ONLY_TIME = Intl.DateTimeFormat('id-ID', {
  hour: 'numeric',
  minute: 'numeric',
})

const ONLY_MONTH = Intl.DateTimeFormat('id-ID', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
})

export const normalizeDate = (date) => {
  if (!date) return ''
  return date.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')
}

export const formatDate = (param, includeDate = true, includeTime = true) => {
  const targetTime = new Date(param);
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  const yesterday = new Date(today.valueOf()).setDate(today.getDate() - 1)
  const inSameWeek = new Date(today.valueOf()).setDate(today.getDate() - 6)

  const hour = `0${targetTime.getHours()}`.slice(-2);
  const minute = `0${targetTime.getMinutes()}`.slice(-2);

  if (today.valueOf() - targetTime < A_DAY) {
    if (!includeDate && includeTime) {
      return `${hour}:${minute}`
    }
    if (includeDate && !includeTime) {
      return `Hari Ini`
    }
    return `Hari Ini ${hour}:${minute}`
  }

  if (yesterday.valueOf() - targetTime < TWO_DAYS) {
    if (!includeDate && includeTime) {
      return `${hour}:${minute}`
    }
    if (includeDate && !includeTime) {
      return `Kemarin`
    }
    return `Kemarin ${hour}:${minute}`
  }

  if (inSameWeek.valueOf() - targetTime < A_WEEK) {
    if (!includeDate && includeTime) {
      return ONLY_TIME.format(targetTime).replace('.', ':')
    }
    if (includeDate && !includeTime) {
      return ONLY_MONTH.format(targetTime).replace('.', ':')
    }
    return WEEKDAYS_FORMATTER.format(targetTime).replace('.', ':')
  }

  if (!includeDate && includeTime) {
    return ONLY_TIME.format(targetTime).replace('.', ':')
  }

  if (includeDate && !includeTime) {
    return ONLY_MONTH.format(targetTime).replace('.', ':')
  }

  return LOCALE_TIME_FORMATTER.format(targetTime)
    .replace('.', ':')
    .replace(',', '')
}

const getTime = (item) => {
  return item.slice(0, -3)
}

export const formatMessages = (messageData) => {
  const results = messageData.map((item, index) => {
    let hideDetail = false
    if (index !== 0) {
      const { createdAt, senderId } = item
      const isBeforeExists = messageData?.[index - 1]
      const isNextExists = messageData?.[index + 1]
      if (isBeforeExists) {
        const { createdAt: beforeCreatedAt, senderId: beforeSenderId } =
          isBeforeExists
        if (beforeSenderId === senderId) {
          hideDetail = getTime(createdAt) === getTime(beforeCreatedAt)
        }
      }
    }

    // if (index !== 0) {
    //   if (isNextExists) {
    //     const { createdAt, senderId } = item
    //     const { createdAt: nextCreatedAt, senderId: nextSenderId } =
    //       isNextExists

    //     if (senderId === nextSenderId) {
    //       hideDetail = createdAt.slice(0, -3) === nextCreatedAt.slice(0, -3)
    //     }
    //   }
    // }

    return {
      ...item,
      createdAt: formatDate(normalizeDate(item.createdAt), false, true),
      hideDetail,
    }
  })

  return results
}

export const formatMessagesGroupByDate = (messageData) => {
  const messages = [...messageData]

  const groups = messages.reduce((groups, item) => {
    // const date = formatDate(normalizeDate(item?.createdAt), true, false)
    const date = item.createdAt.split(' ')[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      displayDivider: formatDate(normalizeDate(groups[date]?.[0]?.createdAt), true, false),
      messages: formatMessages(groups[date])
    };
  })

  return groupArrays
}