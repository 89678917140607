import { useCallback, useState } from 'react';
import useChatServices from '../services/use-chat-services';
import { formatDate, normalizeDate } from '../utils/date-utils';
import { formatLastMessage } from '../utils/message-formatter';
import { getInitial } from '../utils/string-utils';
import { validateObject } from '../utils/validation-utils';
import useChatStore from './use-chat-store';
import useSocket from '../../../hooks/useSocket';
import { useSelector } from 'react-redux';

const limit = 8;

const generateQuery = (params) => {
  const { search, typeUser, pageList, limit } = params
  const result = validateObject({
    limit,
    search,
    page: pageList || 1,
    conversation_user: typeUser || 'seller',
  })
  return result
}

const useChatLists = () => {
  const { socket } = useSocket()
  const { username } = useSelector(state => state)
  const { openRoomStore } = useChatStore()
  const { callAPI } = useChatServices()
  const [isLoadingList, setIsLoadingList] = useState(false)
  const [isErrorList, setIsErrorList] = useState(false)
  const [messageList, setMessageList] = useState('')
  const [conversationList, setConversationList] = useState([])

  const [notifications, setNotifications] = useState({
    mitra: {
      count: 0,
      active: false,
    },
    seller: {
      count: 0,
      active: false,
    },
  })

  const [unreadMessages, setUnreadMessages] = useState({});
  const [totalUnread, setTotalUnread] = useState(0)
  const [typeUser, setTypeUser] = useState("seller")
  const [search, setSearch] = useState('')
  const [pageList, setPageList] = useState(1)
  const [hasMoreList, setHasMoreList] = useState(false)

  const formattedLists = conversationList?.map((item) => {
    const selected = item.id === openRoomStore.id
    return {
      ...item,
      initial: getInitial(item?.title || ''),
      lastMessage: formatLastMessage(item?.lastMessage),
      formattedDate: formatDate(normalizeDate(item.date)),
      selected,
    }
  })

  const updateConversation = useCallback(
    (
      data = [],
      {
        search,
        typeUser,
        pageList,
        pagination,
        hasMore,
      }
    ) => {
      let newData = []
      if (pageList === 1) {
        newData = data
      } else {
        newData = [...conversationList, ...data]
      }
      setPageList(
        pageList
      )
      setConversationList(newData)
      setHasMoreList(hasMore)
    }, [conversationList])

  const fetchLists = useCallback(
    async (params = {}) => {
      setIsLoadingList(true)
      setIsErrorList(false)
      setMessageList('')
      try {
        const query = generateQuery(params)
        const response = await callAPI({
          url: '/conversation',
          method: 'get',
          params: {
            ...query,
            type: 'personal',
          },
        }).catch((err) => err.response)
        if (response?.status > 300) {
          const message = response?.data?.message
          throw new Error(message || 'Error occured')
        }

        if (response?.data?.success && response?.status === 200) {
          const { data, pagination } = response?.data?.data
          const { has_more: hasMore } = response?.data

          /**
           * Emit data to socket room
           */
          const rooms = data?.map((item) => item.id);
          socket?.emit('joinRoom', {
            username,
            dataRooms: rooms,
          });

          updateConversation(
            data,
            {
              search: params?.search,
              typeUser: params?.typeUser,
              pageList: params?.pageList,
              pagination,
              hasMore,
            }
          )
        }

      } catch (err) {
        console.log({ err })
        let message = err?.message
        if (err?.response?.data?.message) {
          message = err?.response?.data?.message
        }
        setConversationList([])
        setMessageList(message ?? 'Error occured')
        setIsErrorList(true);
      } finally {
        setTimeout(() => {
          setIsLoadingList(false)
        }, 600)
      }
    }, [callAPI, socket, updateConversation, username])

  const fetchNotifications = async () => {
    try {
      const response = await callAPI({
        url: '/notifications',
        method: 'get',
        params: {
          limit,
          types: 'seller,mitra',
        },
      }).catch((err) => err.response)
      if (response?.status > 300) {
        const message = response?.data?.message
        throw new Error(message || 'Error occured')
      }
      if (response?.data?.success && response?.status === 200) {
        setNotifications((prev) => ({
          ...prev,
          seller: {
            count: response?.data?.data?.seller,
            active: response?.data?.data?.seller > 0
          },
          mitra: {
            count: response?.data?.data?.mitra,
            active: response?.data?.data?.mitra > 0
          }
          // ...response?.data?.data
        }))
      }
    } catch (err) { }
  }

  const addMessageToList = (msg) => {
    const { conversationId: id } = msg
    const messageOnList = conversationList?.find((i) => i.id === id)
    if (messageOnList) {
      return setConversationList((prev) => {
        const messageOnList = prev?.find((i) => i.id === id)
        if (messageOnList) {
          return prev?.map((i) => {
            if (i.id === id) {
              if (i.id === openRoomStore?.id) {
                return {
                  ...messageOnList,
                  date: msg?.createdAt,
                  lastMessage: msg?.message,
                }
              }
              return {
                ...messageOnList,
                date: msg?.createdAt,
                lastMessage: msg?.message,
                unread: i?.unread + 1,
              }
            }
            return i
          })
        }
        return prev
      })
    }
    // fetchLists()
    return
  }

  const handleSearch = useCallback(
    event => {
      setSearch(event?.target?.value);
      setPageList(1)
    }, [])

  const resetSearch = useCallback(
    () => {
      setSearch('');
    }, [])

  const handleTypeUser = useCallback(
    (value) => {
      setSearch('')
      setTypeUser(value);
      setPageList(1)
    }, [])

  return {
    isLoadingList,
    isErrorList,
    messageList,
    conversationList,
    setConversationList,
    conversationLists: formattedLists,
    fetchLists,
    unreadMessages,
    setUnreadMessages,
    totalUnread,
    setTotalUnread,
    typeUser,
    setTypeUser,
    search,
    setSearch,
    resetSearch,
    pageList,
    setPageList,
    hasMoreList,
    handleSearch,
    handleTypeUser,
    notifications,
    setNotifications,
    fetchNotifications,
    addMessageToList,
  }
}

export default useChatLists
