import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { IconButton, Slide } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TypoTitle } from '../../organisms/VerifyAccount/style'
import useOutsideClick from '../../../../hooks/useOutsideClick'

const StyledModal = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  outline: 2px solid transparent;
  outline-offset: 2px;
  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  background-color: rgb(38 38 38 / 0.7);
`

const StyledModalWrapper = styled.div`
  position: relative;
  width: 450px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem/* 24px */;
  margin-bottom: 1.5rem/* 24px */;
  overflow: hidden;
  ${({ fullWidth }) => {
    if (fullWidth) {
      return css`
        width: 100% !important;
        height: 100% !important;
      `
    }
  }}
`

const StyledModalRelative = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  background-color: #FFF;
  border-radius: 0.5rem/* 8px */;
  outline: 2px solid transparent;
  outline-offset: 2px;
`

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem/* 12px */;
  padding: 0.75rem/* 12px */;
  border-top-left-radius: 0.25rem/* 4px */;
  border-top-right-radius: 0.25rem/* 4px */;
  position: relative;
  border-bottom-width: 1px;
  box-shadow: 0 4px 2px -2px #ededed;
`

export const ModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: .8rem;
  flex: 1 1 auto;
  padding: 0.75rem/* 12px */;
  overflow: auto;
  min-height: 200px;
  max-height: 500px;
  &::-webkit-scrollbar {
    background: transparent;
    width: 0.3rem;
    height: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(21, 21, 21, 0.3);
    border-radius: 1000px;
  }
`

export const ModalFooter = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  padding: 0.75rem/* 12px */;
`

export const ModalHeader = ({ text, handleClose, children, ...props }) => {
  return (
    <StyledModalHeader>
      {text ? (
        <TypoTitle>{text}</TypoTitle>
      ) : (
        <TypoTitle>{children}</TypoTitle>
      )}
      <IconButton onClick={handleClose} size="small">
        <Close fontSize="inherit" />
      </IconButton>
    </StyledModalHeader>
  )
}

const Modal = ({
  open = false,
  onClose,
  disableOutsideClick = false,
  disabled,
  children,
  header = {},
  body = {},
  footer = {},
  ...props
}) => {
  const isFooterExists = useMemo(() => Boolean(Object.keys(footer)?.length), [footer])
  const isBodyExists = useMemo(() => Boolean(Object.keys(body)?.length), [body])
  const [showModal, setShowModal] = useState(open)

  useEffect(() => {
    setShowModal(open)
  }, [open])

  const handleClose = useCallback(() => {
    if (disabled) return
    setShowModal(false)
    setTimeout(() => {
      onClose()
    }, 300)
  }, [disabled, onClose])

  const modalRef = useRef()
  useOutsideClick(modalRef, handleClose)

  if (!showModal) {
    return null
  }

  return (
    <StyledModal>
      <StyledModalWrapper>
        <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
          <StyledModalRelative ref={modalRef}>
            <ModalHeader {...header} handleClose={handleClose}>{header?.text}</ModalHeader>
            {children && (
              children
            )}
            {!children && isBodyExists && <ModalBody {...body} />}
            {!children && isFooterExists && <ModalFooter {...footer} />}
          </StyledModalRelative>
        </Slide>
      </StyledModalWrapper>
    </StyledModal>
  )
}

export default Modal