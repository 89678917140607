import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@material-ui/core";
import { CheckCircleOutline, HighlightOff, InfoOutlined } from "@material-ui/icons";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const CardVerifyAccount = styled.div`
  box-sizing: border-box;
  background-color: white;
  position: relative;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(49, 53, 59, 0.15) 0px 1px 6px 0px;
  padding: 1rem;
`

export const Content = styled.div`
  width: auto;
  margin: 0 auto;
  display: flex;
`

export const FooterContent = styled(Content)`
  position: sticky;
  bottom: 0;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`

export const TypoTitle = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #202939;
`

export const TypoSubTitle = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4B5565;
`

export const VerifyAccountWrapper = styled(CardVerifyAccount)`
  width: 100%;  
  max-width: 768px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
`

export const VerifyButton = styled(Button)`
  align-self: flex-end !important;
  display: flex !important;
  height: 44px !important;
  padding: 10px 18px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  align-self: stretch !important;
  border-radius: 8px !important;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  text-transform: capitalize !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;

  ${({ primary, disabled }) => {
    if (disabled) {
      return css`
        border: none !important;
        color: rgba(0, 0, 0, 0.26) !important;
        box-shadow: none;
        background-color: #cad3e1 !important;
      `
    }
    if (primary) {
      return css`
      border: none !important;
      background: #0068E1 !important;
      color: #FFF !important;
      `
    }
    else {
      return css`
        border: 1px solid #141414 !important;
        background: #fff !important;
        color: #364152 !important;
      `
    }
  }}
`;

export const Identity = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: .8rem;
  & > div {
    width: inherit;
  }
  & img {
    width: 100%;
    border-radius: 8px;
  }
`

export const ModalHelper = styled.div`
  width: 100%;
  padding: 1rem;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 59, 162, 1);
  background: rgba(240, 249, 254, 1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &>span{
    font-size: 14px;
  }
`

export const AccountVerifyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const AccountVerifyHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  font-weight: 700;
  font-size: 14px;
  
  &>.title{
  }
  &>.action{
    cursor: pointer;
    color: #015EB6;
  }
`
export const AccountVerifyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const AccountVerifyContentItem = styled.div`
  
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(75, 85, 101, 1);

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  
  & > span {
    width: 30%;
  }
  & > span:nth-child(2) {
    flex:1;
  }
`

const SAccountVerifyBadgeStatus = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  padding: 4px 10px;
  border-radius: 16px;
  gap: 4px;
  ${({ status }) => {
    switch (status) {
      case 'verify':
        return css`
          background: rgba(224, 242, 254, 1);
          color: rgba(3, 105, 161, 1);
        `;

      case 'accept':
        return css`
          background: rgba(220, 252, 231, 1);
          color: rgba(21, 128, 61, 1);
        `;

      case 'reject':
        return css`
          background: rgba(254, 226, 226, 1);
          color: rgba(185, 28, 28, 1);
        `;

      default:
        return css`
          background: rgba(255, 237, 213, 1);
          color: rgba(194, 65, 12, 1);
        `;
    }
  }}
`

export const AccountVerifyBadgeStatus = () => {
  const { account } = useSelector(state => state)
  const { status, statusInfo } = useMemo(() => account?.data?.ktp || {}, [account])

  return (
    <SAccountVerifyBadgeStatus status={status}>
      {status === 'accept' && <CheckCircleOutline fontSize='small' />}
      {status === 'reject' && <HighlightOff fontSize='small' />}
      {(status === '' || status === 'verify') && <InfoOutlined fontSize='small' />}
      {statusInfo}
    </SAccountVerifyBadgeStatus>
  )
}