import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CHAT_API_URL } from '../config'

const useChatServices = () => {
  const { chatToken } = useSelector((state) => state)

  const [controller, setController] = useState(null)

  const callAPI = async (requestConfig) => {
    const ctrl = new AbortController()
    setController(ctrl)
    const { url, ...options } = requestConfig
    options.url = `${CHAT_API_URL}${url}`
    options.headers = {
      Authorization: `Bearer ${chatToken}`,
      'Access-Control-Allow-Origin': '*',
    }
    // options.headers = {
    //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJzb25faWQiOiJ3b25kZXJraWRkMDAwc3FFciIsImZ1bGxfbmFtZSI6IlRva28gU2VyYmEgQWRhIiwicHJvZmlsZV9waWN0dXJlIjoiaHR0cHM6Ly9pbWFnZXMuYmFsZW9tb2wuY29tL3N0YWdpbmcvdXBsb2Fkcy93b25kZXJraWRkMDAwc3FFci8yMDIzL3Byb2ZpbGUvMTY3NDA5MTA2Njc4NzA0MjA0MTY2NjY2Mjc2MDY5MjUzODU2a2VyYW5qYW5nLnBuZz93aWR0aD0xMDAmaGVpZ2h0PTEwMCIsImVkaXRvciI6IndvbmRlcmtpZGQwMDBzcUVyIiwicm9sZSI6InNlbGxlciIsImFjY2VzcyI6InJlYWQvd3JpdGUiLCJpYXQiOjE2OTUwMjE0ODcsImV4cCI6MTY5NTI4MDY4N30.O8Wzt7EL75R8BkAYryV_ROPHKXFo9kzSXvqsWugsHRk`,
    //   'Access-Control-Allow-Origin': '*',
    // }

    return await axios.request({ ...options, signal: ctrl.signal })
  }

  useEffect(() => {
    return () => {
      controller && controller.abort()
    }
  }, [controller])

  return { callAPI }
}

export default useChatServices
