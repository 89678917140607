const ActionType = {
    ADD_ORDER: "ADD_ORDER",
    MINUS_ORDER: "MINUS_ORDER",
    RESET_ORDER: "RESET_ORDER",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    FOLLOWING: "FOLLOWING",
    READYCHAT: "READYCHAT",
    RESET_UNREAD_MESSAGE: "RESET_UNREAD_MESSAGE",
    SET_PREVIEW_CHAT_ORDER: "SET_PREVIEW_CHAT_TYPE",
    RESET_PREVIEW_CHAT_ORDER: "RESET_PREVIEW_CHAT_ORDER",
    SET_PREVIEW_CHAT_PRODUCT: "SET_PREVIEW_CHAT_PRODUCT",
    SET_DEVICE: "SET_DEVICE",
    OPEN_CHAT_MBL: "OPEN_CHAT_MBL",
    IN_DISCUSS: "IN_DISCUSS",
    LIST_DISCUSS: "LIST_DISCUSS",
    NEW_CHAT_GROUP: "NEW_CHAT_GROUP",
    EMIT_NEW_CHAT_GROUP: "EMIT_NEW_CHAT_GROUP",
    IS_AFFILIATOR: "IS_AFFILIATOR",
    IS_LP: "IS_LP",
    TOTAL_ORDER_SUCCESS: "TOTAL_ORDER_SUCCESS",
    OPEN_DISCUSS_LP: "OPEN_DISCUSS_LP",
    EMIT_NEW_CHAT_LP: "EMIT_NEW_CHAT_LP",
    NEW_CHAT_LP: "NEW_CHAT_LP",
    SEARCH_CHAT: "SEARCH_CHAT",
    OTP_BANK: "OTP_BANK",
    OTP_PIN: "OTP_PIN",
    IS_FREE_UPGRADE_LP: "IS_FREE_UPGRADE_LP",
    IS_EDITOR_LP: "IS_EDITOR_LP",
    SET_IS_SUBMISSION: "SET_IS_SUBMISSION",
    IN_DISCUSS2: "IN_DISCUSS2",
    LIST_DISCUSS2: "LIST_DISCUSS2",
    EMIT_NEW_CHAT_GROUP2: "EMIT_NEW_CHAT_GROUP2",
    RUNNING_TEXT: "RUNNING_TEXT",
    IN_DISCUSS_LP: "IN_DISCUSS_LP",
    LIST_DISCUSS_LP: "LIST_DISCUSS_LP",
    PROFILE_REFRESH: "PROFILE_REFRESH",
    FACEBOOK_ACCOUNT: "FACEBOOK_ACCOUNT",
    IS_CS_LP: "IS_CS_LP",
    USER_ROLE: "USER_ROLE",
    SET_ACCOUNT: "SET_ACCOUNT",
    SET_BALANCE: "SET_BALANCE",
    SET_VOUCHER: "SET_VOUCHER",
    SET_STATUS_LP: "SET_STATUS_LP",
    SET_STATUS_DEBT: "SET_STATUS_DEBT",
    SET_ADDR_CHECK: "SET_ADDR_CHECK",
    SET_FB_CHECK: "SET_FB_CHECK",
    SET_NEW_MEMBER_MODAL: "SET_NEW_MEMBER_MODAL",
    SET_MASS_CART_DATA: "SET_MASS_CART_DATA",
    SET_IS_FEEDS_PAGE: "SET_IS_FEEDS_PAGE",
    SET_PAYLATER: "SET_PAYLATER",
    SET_LAST_WITHDRAWAL: "SET_LAST_WITHDRAWAL",
    SET_UNREAD_MESSAGES: 'SET_UNREAD_MESSAGES',
    RESET_UNREAD_MESSAGES: 'RESET_UNREAD_MESSAGES',
    SET_BROADCAST_NOTIFICATION: 'SET_BROADCAST_NOTIFICATION',
    // HIDE_TEMPLATE: 'HIDE_TEMPLATE',
    // SHOW_TEMPLATE: 'SHOW_TEMPLATE',
    OTP_REGISTER_SUPPLIER: 'OTP_REGISTER_SUPPLIER',
    SET_CHAT_LIST_TYPE: 'SET_CHAT_LIST_TYPE',
}

export default ActionType;