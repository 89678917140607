import React from 'react';
import LazyLoad from 'react-lazy-load';
import useResponsiveAssets from '../../hooks/useResponsiveAssets';
import { useDefault } from '../../hooks/useDefault';

const ResponsiveImage = ({ src, alt = '', srcSet = {}, type = '', ratio = '', ...props }) => {
  let sourceWEBP = null
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    sourceWEBP = useResponsiveAssets({ src, breakpoints: srcSet, type, ratio, format: 'webp' })
  } catch (error) {
    console.log(error)
    console.log({ src, alt, srcSet, type, ratio, ...props })
  }
  return (
    <LazyLoad>
      <img
        src={sourceWEBP}
        alt={alt}
        {...props}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = type.match(/product/gi) ? useDefault.getDefaultImageProduct : useDefault.getDefaultImage;
        }}
      />
    </LazyLoad >
  );
};

export default ResponsiveImage;