/* eslint-disable no-unused-vars */
import React, { createContext, memo, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useAccountDetailData } from '../features/account/hooks/use-account-detail-data';
import ActionType from '../redux/reducer/globalActionType';
import useSubscriptions from './use-subscriptions';

const isNeedUpdate = (data) => {
  const { updated } = data;
  if (typeof updated === 'undefined') {
    return true;
  }

  if (data?.lastPaymentTimestamp) return true;

  if (Date.now() - updated > 3600 * 1000) {
    return true;
  }

  return false;
};

const AccountContext = createContext(null);

const AccountProviderComponent = ({ children, isLogin, idMember, token, chatToken, reduxAccount, setAccount }) => {
  useSubscriptions({ chatToken, isLogin });

  const { data, isLoading, isError, refetch } = useAccountDetailData();

  const value = useMemo(() => ({
    token,
    idMember,
    account: reduxAccount?.data,
    isLoading,
    isExists: reduxAccount?.data && Object.keys(reduxAccount?.data).length > 0,
    getAccount: refetch,
    setAccount,
  }), [token, idMember, reduxAccount?.data, isLoading, refetch, setAccount]);

  useEffect(() => {
    if (isLogin) {
      if (!Object.keys(reduxAccount.data).length > 0 || isNeedUpdate(reduxAccount)) {
        refetch();
      }
    }
  }, [isLogin, reduxAccount, refetch]);

  return (
    <AccountContext.Provider value={value}>
      {children}
    </AccountContext.Provider>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setAccount: (account) =>
      dispatch({
        type: ActionType.SET_ACCOUNT,
        payload: { ...account }
      }),
  }
}

const mapStateToProps = state => {
  return {
    isLogin: state.isLogin,
    idMember: state.idMember,
    token: state.token,
    chatToken: state.chatToken,
    reduxAccount: state.account,
  }
}

const memoizedComponent = memo(AccountProviderComponent)

export const AccountProvider = connect(mapStateToProps, mapDispatchToProps)(memoizedComponent);

export const useAccount = () => useContext(AccountContext)
