/* eslint-disable no-unused-vars */
import { checkStringUrl, fileNameExtractor } from "./string-utils"

export const formatLastMessage = (string) => {
  const isContainUrlValue = checkStringUrl(string)
  if (isContainUrlValue) {
    const fileName = fileNameExtractor(string)
    if (fileName) {
      return fileName
    }
    return 'link'
    // return fileNameExtractor(string)
  }
  return string
}