import { useCallback, useEffect } from 'react';
import { requestNotificationPermission, subscribeUser } from '../utils/notification-services';
import { useLocalStorage } from './useLocalStorage';

const useSubscriptions = ({ chatToken, isLogin }) => {
  const { setLocalStorageValue, getLocalStorageValue } = useLocalStorage();

  const handleAllowNotifications = useCallback(async () => {
    if (!chatToken) return; // Early return if chatToken is not available

    try {
      await requestNotificationPermission();
      await subscribeUser(chatToken);
      setLocalStorageValue('subscription-member', true);
    } catch (error) {
      console.error('Error subscribing user:', error);
    }
  }, [chatToken, setLocalStorageValue]);

  useEffect(() => {
    if (!isLogin) return; // Only run if user is logged in

    const isSubscribe = getLocalStorageValue('subscription-member');
    if (!isSubscribe) {
      handleAllowNotifications();
    }

    return () => {
      // Clean up logic for subscription, if needed
      setLocalStorageValue('subscription-member', false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);
};

export default useSubscriptions;
