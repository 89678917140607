export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const IMAGE_TYPES = {
  avatar: {
    default: 10,
    xs: 60,
    lg: 90,
    xl: 120,
  },
  banner: {
    default: 100,
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
  },
  icon: {
    default: 10,
    xs: 50,
    sm: 60,
    xl: 80,
  },
  mitra: {
    default: 30,
    xs: 190,
    md: 240,
    xl: 280,
  },
  mitraSm: {
    default: 10,
    xs: 60,
    lg: 90,
    xl: 120,
  },
  profile: {
    default: 30,
    xs: 190,
    md: 240,
    xl: 280,
  },
  profileSm: {
    default: 10,
    xs: 60,
    lg: 90,
    xl: 120,
  },
  profileDetail: {
    default: 50,
    xs: 200,
    md: 240,
    lg: 320,
    xl: 360,
  },
  productSm: {
    default: 10,
    xs: 60,
    lg: 90,
    xl: 120,
  },
  product: {
    default: 30,
    xs: 190,
    md: 240,
    xl: 280,
  },
  productDetail: {
    default: 50,
    xs: 200,
    md: 240,
    lg: 320,
    xl: 360,
  },
  productThumbnail: {
    default: 50,
    xs: 60,
    lg: 90,
    xl: 120,
  },
  slider: {
    default: 100,
    xs: 320,
    sm: 360,
    md: 420,
    lg: 480,
    xl: 600,
  },
  sliderLg: {
    default: 100,
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
  },
};

export const DEFAULT_WIDTH = 20;

export const ASSET_REGEX = new RegExp('(?:https?://)?(?:www.)?(?:images.baleomol.com)', 'gi');
