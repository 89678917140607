import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailMember } from '../services';
import ActionType from '../../../redux/reducer/globalActionType';
import { APP_VERSION_NAME } from '../../../config';

const setAccount = (account) => ({
  type: ActionType.SET_ACCOUNT,
  payload: { ...account }
});

const setFacebookCheck = (setting) => ({
  type: ActionType.SET_FB_CHECK,
  payload: setting,
});

const setAffiliatorKhusus = (isafltr) => ({
  type: ActionType.IS_AFFILIATOR,
  payload: { isafltr: isafltr }
});

const setTotalOrderSuccess = (totalOrderSuccess) => ({
  type: ActionType.TOTAL_ORDER_SUCCESS,
  payload: { totalOrderSuccess: totalOrderSuccess }
});

const setIsFreeUpgradeLP = (isfreeuplp) => ({
  type: ActionType.IS_FREE_UPGRADE_LP,
  payload: { isfreeuplp: isfreeuplp }
});

const setFacebookAccount = (fbUrl, fbAccount) => ({
  type: ActionType.FACEBOOK_ACCOUNT,
  payload: { fbUrl: fbUrl, fbAccount: fbAccount }
});

const useAccountDetailData = () => {
  const dispatch = useDispatch();
  const { idMember, token, username, fbcheck: fbCheck } = useSelector(state => state);

  const queryKey = ['useAccountDetailData', idMember, username];

  return useQuery({
    queryKey: queryKey,
    queryFn: () => getDetailMember({ idMember, token }),
    cacheTime: 1000 * 60 * 60, // 1 jam
    staleTime: 1000 * 60 * 60, // 1 jam
    refetchOnWindowFocus: false,
    retry: 1,
    onSuccess: (data) => {
      const appVersionLocal = sessionStorage.getItem(APP_VERSION_NAME);
      const appVersion = data?.appVersion;

      if (!appVersionLocal) {
        sessionStorage.setItem(APP_VERSION_NAME, appVersion);
      }

      if (appVersionLocal && appVersionLocal !== appVersion) {
        sessionStorage.removeItem(APP_VERSION_NAME);
        window.location.reload(true);
        return;
      }

      const { akunFacebook, urlFacebook } = data?.data;

      dispatch(setAccount({
        data: { ...data.data },
        updated: Date.now(),
      }));
      dispatch(setFacebookCheck({
        ...fbCheck,
        isfbexists: !!akunFacebook.trim() && !!urlFacebook.trim(),
      }));
      dispatch(setAffiliatorKhusus(data?.data?.isAffiliatorKhusus));
      dispatch(setTotalOrderSuccess(data?.data?.totalTransaction));
      dispatch(setIsFreeUpgradeLP(data?.data?.isRecommendedUpgradeLandingpage));
      dispatch(setFacebookAccount(data?.data?.urlFacebook, data?.data?.akunFacebook));
    },
    onError: (error) => {
      console.error('Error fetching account details:', error);
    },
    enabled: !!idMember && !!token, // Ensure query is only enabled if idMember and token are present
  });
};

export { useAccountDetailData };
