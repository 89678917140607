/* eslint-disable no-unused-vars */
import { useState } from 'react'
import useChatServices from '../services/use-chat-services'
import { formatMessagesGroupByDate } from '../utils/date-utils'

const useChatConversations = () => {
  const { callAPI } = useChatServices()
  /**
   * initial state for chat conversations 
   */
  const [messages, setMessages] = useState([])
  const isMessageExists = messages?.length > 0
  const [hasMoreMessages, setHasMoreMessages] = useState(false)
  const [lastKey, setLastKey] = useState('')
  const [scrollTo, setScrollTo] = useState('')
  const [isLoadingMessages, setIsLoadingMessages] = useState(false)
  const [isErrorMessages, setIsErrorMessages] = useState()
  const [isLoadingSendAttachment, setIsLoadingSendAttachment] = useState(false)
  const [isLoadingSendMessage, setIsLoadingSendMessage] = useState(false)
  const [isLoadingSendMessagePreview, setIsLoadingSendMessagePreview] = useState(false)
  const [messageMessages, setMessageMessages] = useState(null)
  const [messagePreview, setMessagePreview] = useState(null)
  const [messageSendAttachment, setMessageSendAttachment] = useState(null)
  const [messageSendMessage, setMessageSendMessage] = useState(null)

  const formattedMessage = () => formatMessagesGroupByDate(messages);

  const fetchMessages = async (id, more = false, params = {}) => {
    if (!id) {
      return false
    }
    try {
      setIsLoadingMessages(true)
      setIsErrorMessages(false)
      setScrollTo('')
      setMessageMessages(null)
      const response = await callAPI({
        url: `/conversation/${id}/message`,
        method: 'get',
        params: {
          ...params,
        },
      }).catch((err) => err.response)
      if (response?.status > 300) {
        const message = response?.data?.message
        throw new Error(message || 'Error occured')
      }
      if (response?.data?.success && response?.status === 200) {
        let newData = []
        if (more) {
          newData = [...messages, ...response?.data?.data?.data]
        } else {
          newData = [...response?.data?.data?.data]
        }
        setMessages(() => {
          return newData
        })
        setHasMoreMessages(response?.data?.hasMore)
        setLastKey(response?.data?.lastKey)
        if (!params?.lastKey) {
          setScrollTo(newData?.[0]?.messageId)
        } else {
          const lastKeyIndex = newData?.findIndex(
            (i) => i?.messageId === params?.lastKey
          )
          const scrolledId = newData?.[Number(lastKeyIndex) + 1]?.messageId
          setScrollTo(scrolledId)
        }
      }
    } catch (err) {
      console.log({ err })
      setMessageMessages('Error ketika mengambil data percakapan')
      setIsErrorMessages(true)
    } finally {
      setIsLoadingMessages(false)
    }
  }

  const handleResponse = (response) => {
    if (response?.status > 300) {
      const errorMessage = response?.data?.message;
      throw new Error(errorMessage || 'Error occurred');
    }
    return response?.data?.data;
  };

  const sendRequest = async (id, dataPost) => {
    const response = await callAPI({
      url: `/conversation/${id}/message`,
      method: 'post',
      data: dataPost,
    }).catch((err) => err.response);
    return handleResponse(response);
  };

  const sendMessagePreview = async (id, previewData = false) => {
    if (!isLoadingSendMessagePreview) {
      try {
        setIsLoadingSendMessagePreview(true)
        setMessagePreview(null)
        const bodyMessage =
          previewData?.type === 'product'
            ? previewData?.data?.id?.toString()
            : previewData?.type === 'order'
              ? previewData?.data?.code?.toString()
              : null;

        const bodyType = previewData?.type === 'product'
          ? 'product_preview'
          : previewData?.type === 'order'
            ? 'order_preview'
            : null;

        if (!bodyType) throw new Error('Invalid previewData type');

        const dataPost = {
          messageType: bodyType,
          message: bodyMessage,
        };

        await sendRequest(id, dataPost);
        return true;
      } catch (error) {
        setMessagePreview('Error ketika mengirimkan pesan preview')
        console.error({ 'Error while executing sendMessage() -> previewData': error });
        return false;
      } finally {
        setIsLoadingSendMessagePreview(false)
      }
    }
  }

  const sendMessage = async ({ id, type, message }, previewData = false) => {
    if (previewData?.type) {
      sendMessagePreview(id, previewData)
    }
    if (message && !isLoadingSendMessage) {
      try {
        setIsLoadingSendMessage(true);
        setMessageSendMessage(null)
        const dataPost = {
          messageType: type,
          message: message.trim(),
        };

        await sendRequest(id, dataPost);
        return true;
      } catch (error) {
        setMessageSendMessage('Error ketika mengirimkan pesan')
        console.error({ 'Error while executing sendMessage()': error });
        return false;
      } finally {
        setIsLoadingSendMessage(false);
      }
    }

    return false;
  };

  const sendAttachment = async ({ id, file }) => {
    try {
      setIsLoadingSendAttachment(true)
      setMessageSendAttachment(null)
      const form = new FormData()
      form.append('file', file)
      const response = await callAPI({
        url: `/conversation/${id}/attachment`,
        method: 'post',
        data: form,
      }).catch((err) => err.response)
      const message = response?.data?.message
      if (response?.status > 300) {
        throw new Error(message || 'Error occured')
      }
      const { data } = response?.data
      // socket.emit('chatMessage', { ...data })
      return true
    } catch (error) {
      const message = error?.message ?? 'Terjadi kesalahan saat mengirim file'
      setMessageSendAttachment(message)
      console.error({ 'Error while executing sendAttachment()': error })
      return false
    } finally {
      setIsLoadingSendAttachment(false)
    }
  }

  const addMessageToConversation = (id, message, messages) => {
    if (message?.conversationId === id) {
      setMessages(() => {
        return [
          message,
          ...messages,
        ]
      })
      setScrollTo(message?.messageId)
    }
  }

  return {
    messages,
    formattedMessages: formattedMessage(),
    setMessages,
    isMessageExists,
    hasMoreMessages,
    setHasMoreMessages,
    lastKey,
    setLastKey,
    scrollTo,
    setScrollTo,
    isLoadingMessages,
    setIsLoadingMessages,
    isErrorMessages,
    setIsErrorMessages,
    isLoadingSendAttachment,
    setIsLoadingSendAttachment,
    isLoadingSendMessage,
    setIsLoadingSendMessage,
    isLoadingSendMessagePreview,
    setIsLoadingSendMessagePreview,
    messageMessages,
    setMessageMessages,
    messagePreview,
    setMessagePreview,
    messageSendAttachment,
    setMessageSendAttachment,
    messageSendMessage,
    setMessageSendMessage,
    formattedMessage,
    fetchMessages,
    sendMessage,
    sendAttachment,
    addMessageToConversation,
  }
}

export default useChatConversations