import React, { createContext, useContext, useState } from 'react'

const ModalLoginContext = createContext(null)

export const ModalLoginProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <ModalLoginContext.Provider value={{ isOpen, setIsOpen }}>{children}</ModalLoginContext.Provider>
  )
}

export const useModalLogin = () => useContext(ModalLoginContext)
