/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Baleomol from './Baleomol';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { useLocalStorage } from './hooks/useLocalStorage';


// Store
// const store = createStore(rootReducer);
const rootURL = process.env.PUBLIC_URL
let swRegistration = null

if ('serviceWorker' in navigator && 'PushManager' in window) {
  // console.log('Service Worker and Push are supported');
  navigator.serviceWorker.register('sw.js')
    .then(function (swReg) {
      // console.log('Service Worker is registered', swReg);
      swRegistration = swReg;
      swReg.pushManager.getSubscription().then((subscription) => {
        const { setLocalStorageValue } = useLocalStorage()
        const isSubscribe = !(subscription === null)
        setLocalStorageValue('subscription-member', isSubscribe)
      })
    })
    .catch(function (error) {
      console.error('Service Worker Error', error);
    });
} else {
  console.warn('Push messaging is not supported');
}


ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <React.StrictMode>
        <Baleomol />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('baleomol')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
