import axios from 'axios'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Backend } from '../services/Config'

const useAxiosFunction = () => {
  const { token } = useSelector((state) => state)
  const [controller, setController] = useState(null)

  const dispatchData = async ({ credentials = false, ...requestConfig }) => {
    const ctrl = new AbortController()
    setController(ctrl)
    const { url, ...options } = requestConfig
    options.url = `${Backend}${url}`
    if (credentials) {
      options.headers = { Authorization: `Bearer ${token}` }
    }
    return await axios.request({ ...options, signal: ctrl.signal })
  }

  useEffect(() => {
    return () => {
      controller && controller.abort()
    }
  }, [controller])

  return { dispatchData }
}

export default useAxiosFunction
