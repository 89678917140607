import React, { createContext, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

const AccountVerifyContext = createContext(null)

export const AccountVerifyProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { account } = useSelector(state => state)
  const accountVerify = useMemo(() => account?.data?.ktp || {}, [account])
  const isAccountVerified = accountVerify?.status === 'accept'
  const isExists = typeof account?.data?.ktp !== 'undefined'

  const value = {
    isModalOpen,
    setIsModalOpen,
    accountVerify,
    isAccountVerified,
    isExists
  }

  return (
    <AccountVerifyContext.Provider value={value}>{children}</AccountVerifyContext.Provider>
  )
}

export const useAccountVerify = () => useContext(AccountVerifyContext)
