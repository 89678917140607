
const publicVapidKey = 'BJcodDv0y_xdE4bLq87EeXGkABmEV4oxG9i900Azy0H_Bxj07zkph7Hi1BkHccknsoikhdrdBdvumfYKMZ0HXrI'; // Replace with your VAPID key

const BE_ENDPOINT = `${process.env.REACT_APP_API_BACKEND_CHAT}`

const setLocalStorageValue = (key, value) => {
  try {
    window.localStorage.setItem(
      key,
      JSON.stringify(value)
    )
  } catch (error) {
    alert('Something went wrong')
  }
}

const getLocalStorageValue = (key) => {
  try {
    const value = window.localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  } catch (error) {
    alert('Something went wrong')
  }
}

export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // console.log('Notification permission granted.');
    } else {
      console.log('Notification permission denied.');
    }
  } catch (error) {
    console.error('An error occurred while requesting notification permission', error);
  }
};

export const subscribeUser = async (token) => {
  const register = await navigator.serviceWorker.ready;
  try {
    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
    });

    // console.log('User is subscribed:', subscription);

    // Send subscription to server to save it
    await fetch(`${BE_ENDPOINT}/subscribe`, {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      }
    });

    register.pushManager.getSubscription().then((subscription) => {
      const isSubscribe = !(subscription === null)
      setLocalStorageValue('subscription-member', isSubscribe)
    })
  } catch (error) {
    console.error('Failed to subscribe the user:', error);
  }
};

export const unsubscribeUser = async () => {
  const register = await navigator.serviceWorker.ready;
  const subscription = await register.pushManager.getSubscription();
  if (subscription) {
    await subscription.unsubscribe();
    // console.log('User is unsubscribed:', subscription);
    // Optionally, you can notify your server about the unsubscription
    await fetch(`${BE_ENDPOINT}/unsubscribe`, {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    setLocalStorageValue('subscription-member', false)
  } else {
    console.log('User is not subscribed.');
  }
};

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
}