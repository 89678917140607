export const fileNameExtractor = (filePath) => filePath.replace(/^.*[\\/]/, '')

export const checkStringUrl = (string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/
  const containsUrlValue = urlRegex.test(string)
  return containsUrlValue
}

export const getInitial = (fullName) => {
  const filteredName = fullName
    .replace(/(?=[^ ]*[^A-Za-z '-])([^ ]*)(?:\\s+|$)/gi, '')
    .trim()
    .replace(/(\s\s+)/gi, ' ');
  const rgx = /(\p{L}{1})\p{L}+/gu;

  let result = [...filteredName.matchAll(rgx)] || [];

  result = (
    (result.shift()?.[1] || '') + (result.pop()?.[1] || '')
  ).toUpperCase();

  return result;
};
