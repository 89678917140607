import { create } from 'zustand'

const useChatStore = create((set) => ({
  isStoreChatOpen: false,
  setIsStoreChatOpen: (newState) => set(() => ({ isStoreChatOpen: newState })),
  openRoomStore: {},
  resetOpenRoomStore: () => set(() => ({
    openRoomStore: {}
  })),
  setOpenRoomStore: (newState) => set(() => ({ openRoomStore: newState })),
  resetOpenChatStore: () => set(() => ({
    isStoreChatOpen: false
  })),
}))

export default useChatStore