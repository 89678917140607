import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router';

const ReferralContext = createContext();

export const referralKey = '__baleoref';
export const fromLinkRefKey = '__baleolinkref';
export const refExpiredKey = '__baleorefexpd';

export const RefContext = (props) => {
  const { children } = props;
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const referral = query.get('ref');
  const localRef = localStorage.getItem(referralKey);
  const expired = localStorage.getItem(refExpiredKey);
  const [refState, setRefState] = useState({
    referral: '',
    from_link_ref: '',
  });

  const setStorage = () => {
    if (referral) {
      const date = new Date();
      date.setDate(date.getDate() + 30);
  
      localStorage.setItem(referralKey, referral);
      localStorage.setItem(refExpiredKey, date.getTime());
      localStorage.setItem(fromLinkRefKey, 'yes');
    } else {
      const date = new Date();
      let executeAdmin = true;
  
      if (localRef && expired) {
        if (date.getTime() < expired) {
          executeAdmin = false;
        }
      }
  
      if (executeAdmin) {
        localStorage.setItem(referralKey, 'admin');
        localStorage.setItem(refExpiredKey, date.getTime());
        localStorage.setItem(fromLinkRefKey, 'no');
      }
    }
  }

  useEffect(() => {
    setStorage();

    setRefState(prev => {
      return {
        ...prev,
        referral: localStorage.getItem(referralKey),
        from_link_ref: localStorage.getItem(fromLinkRefKey),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referral]);

  return (
    <ReferralContext.Provider value={refState}>
      {children}
    </ReferralContext.Provider>
  );
};

const useReferral = () => useContext(ReferralContext);

export default useReferral;