/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clientIO from 'socket.io-client';
import ActionType from '../redux/reducer/globalActionType';

const API_CHAT = process.env.REACT_APP_API_BACKEND_CHAT;
const INITIAL_WS_CONFIG = {
  query: null,
  transports: ['websocket', 'polling'],
};
const INITIAL_CONTEXT_VALUE = {
  socket: null,
  newMessage: {},
  typing: {},
};


const SocketContext = createContext();

export const SocketComponent = ({ children, ...props }) => {
  const {
    isOpenChatMobile,
    username,
    isLogin,
    chatToken,
    inDiscussPage,
    listDiscussConversation,
    toEmitNewMessageLP,
    openDiscussLP,
    setNewChatGroup,
    toEmitNewMessage,
    setNewEmitMessage,
  } = props

  const [token, setToken] = useState(null);
  const [socket, setSocket] = useState(null);
  const [contextValue, setContextValue] = useState({
    ...INITIAL_CONTEXT_VALUE,
  });

  useEffect(() => {
    if (!token && isLogin) {
      setToken(chatToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatToken])

  useEffect(() => {
    if (!socket && token) {
      const query = { token: chatToken };
      const wsConfig = { ...INITIAL_WS_CONFIG, query };
      setSocket(clientIO(API_CHAT, wsConfig));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, token]);

  useEffect(() => {
    setContextValue(prev => ({
      ...prev,
      socket,
      token,
    }))
  }, [token, socket])

  useEffect(() => {
    /**
   * Join Conversation Discuss
   */
    if (socket && inDiscussPage && listDiscussConversation) {
      socket.emit('joinRoom', { username: username, dataRooms: listDiscussConversation });
      socket.off("messageGroup");
      socket.on("messageGroup", message => {
        setNewChatGroup(message);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inDiscussPage,
    listDiscussConversation,
    socket,
  ])

  useEffect(() => {
    /**
     * New Message Emmitter
     */
    if (toEmitNewMessage) {
      socket?.emit("chatMessage", toEmitNewMessage);
      setNewEmitMessage(undefined);
    }
    // eslint-disable-next-line
  }, [toEmitNewMessage]);

  useEffect(() => {
    if (socket && !isLogin) {
      // socket.off('message')
      // socket.off('messageV2')
      // socket.off('messageGroup')
      socket.disconnect();
    }
  }, [socket, isLogin])

  useEffect(() => {
    if (socket) {
      socket.on("disconnect", () => {
        console.log('Socket disconnected')
      });
    }
  }, [socket])

  // useEffect(() => {
  //   let timeoutID = null;

  //   setContextValue((prev) => {
  //     return { ...prev, typing };
  //   });

  //   if (typing) {
  //     timeoutID = setTimeout(() => {
  //       setContextValue((prev) => {
  //         return { ...prev, typing: {} };
  //       });
  //     }, 3000);
  //   }

  //   return () => clearTimeout(timeoutID);
  // }, [typing]);

  // useEffect(() => {
  //   let timeoutID = null;

  //   setContextValue((prev) => {
  //     return { ...prev, newMessage };
  //   });

  //   if (newMessage) {
  //     timeoutID = setTimeout(() => {
  //       setContextValue((prev) => {
  //         return { ...prev, newMessage: null };
  //       });
  //     }, 5000);
  //   }

  //   return () => clearTimeout(timeoutID);
  // }, [newMessage]);

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    isOpenChatMobile: state.opnctmbl,
    isFeedsPage: state.isFeedsPage,
    screen: state.tpdvc,
    username: state.username,
    isLogin: state.isLogin,
    chatToken: state.chatToken,
    inDiscussPage: state.dscspg,
    listDiscussConversation: state.lsdscss,
    toEmitNewMessage: state.sndnwctgrp,
    toEmitNewMessageLP: state.sndnwctgrplp,
    openDiscussLP: state.opndscslp,
    token: state.token,
    userRole: state.userrole,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTypeDevice: type =>
      dispatch({
        type: ActionType.SET_DEVICE,
        payload: { type: type }
      }),
    setNewChatGroup: message =>
      dispatch({
        type: ActionType.NEW_CHAT_GROUP,
        payload: { message: message }
      }),
    setNewChatLP: message =>
      dispatch({
        type: ActionType.NEW_CHAT_LP,
        payload: { message: message }
      }),
    setNewEmitMessage: (message) =>
      dispatch({
        type: ActionType.EMIT_NEW_CHAT_GROUP,
        payload: {
          message: message
        }
      }),
    setNewEmitMessageLP: (message) =>
      dispatch({
        type: ActionType.EMIT_NEW_CHAT_LP,
        payload: {
          message: message
        }
      }),
  };
};

export const SocketProvider = connect(mapStateToProps, mapDispatchToProps)(SocketComponent);

const useSocket = () => useContext(SocketContext);

export default useSocket;
